@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


*{
  font-family: "Lora", serif;
  font-style: italic;
}


/* .lora-font {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
} */

.great-vibes-regular {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
}

